import { IResortTopRated } from 'api/home/types';
import { Stars } from 'components/resort/Stars';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import classNames from 'classnames';
import { NextLink } from 'components/blocks/NextLink';
import { useRef } from 'react';
import { Routes } from 'routes';
import DefaultImage from 'public/resort_header.jpg';
import { useVisibilityWindow } from 'util/useVisibilityWindow';
import { BLUR_CONTENT_STYLE_NAME } from 'theme';
import styles from './styles.module.scss';

export const ResortInfo = ({
  data,
  isLoading,
  index,
}: {
  data: IResortTopRated,
  isLoading: boolean,
  index: number,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useVisibilityWindow(ref, true);
  const { t } = useOTSTranslation('common');
  const style = data.resort.smallImage ? { backgroundImage: `url(${data.resort.smallImage})` } : { backgroundImage: `url(${DefaultImage.src})` };

  return (
    <>
      <div className={classNames(styles.box, styles.resortCard, isLoading ? BLUR_CONTENT_STYLE_NAME : '')} ref={ref}>
        <NextLink href={Routes.resortTripPlanningOverview(data.resortPath)}>
          <span className={classNames('h2', styles.title)}>
            <span className={styles.index}>{index}</span>
            {data.resort.title}
          </span>
          <figure style={isVisible ? style : {}} tabIndex={-1} />
        </NextLink>
        <footer>
          <NextLink href={Routes.resortTripPlanningReviews(data.resortPath)}>
            <div className={styles.stars}>
              <Stars amount={data.resort.rating} />
              {data.resort.rating && (
              <div className={styles.number}>
                (
                { data.resort.rating.toFixed(1) }
                )
              </div>
              )}
            </div>
            <div className={styles.reviewsCount}>
              {t('region.resortGuide.reviews', { count: data.resort.reviewsCount })}
            </div>
          </NextLink>
        </footer>
      </div>
    </>
  );
};
